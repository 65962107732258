import React, { useState } from 'react';
import Axios from 'axios';
import { restserver } from './index.js';
import './App.css';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import logo from './pictures/image.png';

function App() {
  const [name, setName] = useState('');
  const [vorname, setVorname] = useState('');
  const [strasse, setStrasse] = useState('');
  const [ort, setOrt] = useState('');
  const [plz, setPlz] = useState('');
  const [telefon, setTelefon] = useState('');
  const [mobil, setMobil] = useState('');
  const [mail, setMail] = useState('');

  const [bankname, setBankname] = useState('');
  const [kontoi, setKontoinhaber] = useState('');
  const [IBAN, setIBAN] = useState('');
  const [BIC, setBIC] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const [sended, setSended] = useState(false);

  const [validated, setValidated] = useState(false);

  const sendMail = (e) => {
    const form = e.currentTarget;
    setErrorMessage('');
    setError(false);
    e.preventDefault();
    if (form.checkValidity() === false) {
      setValidated(true); // If form is invalid, set validation state to true
    } else {
      setValidated(false);
      Axios.post(restserver, {
        name,
        vorname,
        strasse,
        ort,
        plz,
        telefon,
        mobil,
        mail,
        bankname,
        kontoinhaber: kontoi,
        iban: IBAN,
        bic: BIC,
      }).then((response) => {
        if (response.data.status === 'error') {
          setErrorMessage(response.data.message);
        }
        if (response.data.status === 'ok' && response.data.versendet !== 'delivered') {
          console.log('Error while mail sending!');
          setError(true);
        }
        if (response.data.status === 'ok' && response.data.versendet === 'delivered') {
          console.log('Erfolgreich gesendet');
          setSended(true);
        }
      }).catch((error) => {
        setErrorMessage("Es ist etwas schief gelaufen");
        setError(true);
      });
    }
  };

  return (
    <div className="box">
      <Container>
        <h1 className="text-center text-black pt-5">Mitgliedsänderung einsenden</h1>
        {sended ? (
          <Alert variant="success">
            <p className="form-group">Du hast deine neuen Daten erfolgreich übermittelt.</p>
          </Alert>
        ) : null}
        {errorMessage ? (
          <Alert variant="danger">
            <p className="form-group">Fehlermeldung: {errorMessage}</p>
          </Alert>
        ) : null}
        {error ? (
          <Alert variant="danger">
            <p className="form-group">Bitte versuche es später noch einmal!</p>
          </Alert>
        ) : null}
        <Row className="justify-content-center align-items-center">
          <Col>
            <Form noValidate validated={validated} onSubmit={sendMail}>
              <Row>
                <Col>
                  <div className="text-center">
                    <img src={logo} alt="Logo" className="mx-auto" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Nachname:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      isInvalid={!name && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie Ihren Nachnamen ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Vorname:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={vorname}
                      onChange={(e) => setVorname(e.target.value)}
                      isInvalid={!vorname && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie Ihren Vornamen ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Straße:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={strasse}
                      onChange={(e) => setStrasse(e.target.value)}
                      isInvalid={!strasse && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie Ihre Straße ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>PLZ:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={plz}
                      onChange={(e) => setPlz(e.target.value)}
                      isInvalid={!plz && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie Ihre Postleitzahl ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Ort:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={ort}
                      onChange={(e) => setOrt(e.target.value)}
                      isInvalid={!ort && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie Ihren Ort ein.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Telefon:</Form.Label>
                    <Form.Control
                      type="text"
                      value={telefon}
                      onChange={(e) => setTelefon(e.target.value)}
                      isInvalid={!telefon && validated}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Mobil:</Form.Label>
                    <Form.Control
                      type="text"
                      value={mobil}
                      onChange={(e) => setMobil(e.target.value)}
                      isInvalid={!mobil && validated}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>E-Mail:</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  isInvalid={!mail && validated}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie Ihre Mail Adresse ein, damit wir Sie benachrichtigen können.
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Bankname:</Form.Label>
                    <Form.Control
                      type="text"
                      value={bankname}
                      onChange={(e) => setBankname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Kontoinhaber:</Form.Label>
                    <Form.Control
                      type="text"
                      value={kontoi}
                      onChange={(e) => setKontoinhaber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>IBAN:</Form.Label>
                    <Form.Control
                      type="text"
                      value={IBAN}
                      onChange={(e) => setIBAN(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>BIC:</Form.Label>
                    <Form.Control
                      type="text"
                      value={BIC}
                      onChange={(e) => setBIC(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    Senden
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
    </div>
  );
}

export default App;
